// this file will include all the functions to load the ad manager
import { adUnitType, gamConfigType, interstitialTriggersType } from "../types";
import { loadScript } from "./utils";

// attach ad manager window object
const googletag = (window.googletag = window.googletag || { cmd: [] });

const GPT_URL = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

const defaults = {
  collapseEmptyDivs: true,
  setCentering: true,
  // lazyloading documentation: https://developers.google.com/publisher-tag/reference#googletag.PubAdsService_enableLazyLoad
  setLazyLoadEnabled: true,
  lazyLoadConfig: {
    // skip lazy prefetching (always fetch ads on page load)
    fetchMarginPercent: -1,
    // render when the slot is in the viewport or just outside.
    renderMarginPercent: 0,
    // A multiplier applied to margins on mobile devices.
    mobileScaling: 2.0,
  },
};

/**
 * Initialize the display module
 *
 * @param gamConfig
 * @returns {Promise<void>} Promise that resolves when display initialization is complete
 */
export function init(gamConfig: gamConfigType) {
  const config = {
    ...defaults,
    ...gamConfig,
    collapseEmptyDivs:
      gamConfig.collapseEmptyDivs ?? defaults.collapseEmptyDivs,
    setCentering: gamConfig.setCentering ?? defaults.setCentering,
    setLazyLoadEnabled:
      gamConfig.setLazyLoadEnabled ?? defaults.setLazyLoadEnabled,
    lazyLoadConfig: {
      fetchMarginPercent:
        gamConfig.lazyLoadConfig.fetchMarginPercent ??
        defaults.lazyLoadConfig.fetchMarginPercent,
      renderMarginPercent:
        gamConfig.lazyLoadConfig.renderMarginPercent ??
        defaults.lazyLoadConfig.renderMarginPercent,
      mobileScaling:
        gamConfig.lazyLoadConfig.mobileScaling ??
        defaults.lazyLoadConfig.mobileScaling,
    },
  };

  return new Promise<void>((resolve, reject) => {
    // load the ad manager script
    loadScript(GPT_URL).catch(() => {
      reject(new Error("GPT script failed to load"));
    });

    // todo: set targeting
    // todo: enable ads
    addToQueue(() => {
      googletag.pubads().enableSingleRequest();
      if (config.collapseEmptyDivs) {
        googletag.pubads().collapseEmptyDivs();
      }
      if (config.setCentering) {
        googletag.pubads().setCentering(true);
      }
      if (config.setLazyLoadEnabled) {
        googletag.pubads().enableLazyLoad(config.lazyLoadConfig);
      }
      googletag.pubads().setForceSafeFrame(true);
      googletag.pubads().disableInitialLoad();
      googletag.pubads().addEventListener("slotRenderEnded", (event) => {
        // fire custom event here since we don't have the slot level configs
        // listener for the displayImpression custom event will handle sending pingbacks
        document.dispatchEvent(
          new CustomEvent("displayImpression", { detail: event }),
        );
      });
      resolve();
    });
  });
}

export function addAdUnit(
  code: string,
  sizes: googletag.GeneralSize,
  divId: string,
): Promise<adUnitType> {
  return new Promise((resolve, reject) => {
    addToQueue(() => {
      const slot: googletag.Slot | null = googletag.defineSlot(
        code,
        sizes,
        divId,
      );
      if (slot) {
        slot.addService(googletag.pubads());
        resolve({
          key: "gpt",
          code,
          type: "banner",
          adUnit: {
            divId,
            slot,
          },
        });
      } else {
        reject(new Error("AdManager: addAdUnit: slot did not create"));
      }
    });
  });
}

export function addInterstitialAdUnit(
  code: string,
  navBarTrigger = true,
  unhideWindowTrigger = true,
) {
  return new Promise<adUnitType>((resolve, reject) => {
    addToQueue(() => {
      const interstitialSlot = googletag.defineOutOfPageSlot(
        code,
        googletag.enums.OutOfPageFormat.INTERSTITIAL,
      );
      if (interstitialSlot) {
        const triggers: interstitialTriggersType = {};
        if (navBarTrigger) triggers.navBar = true;
        if (unhideWindowTrigger) triggers.unhideWindow = true;

        interstitialSlot.addService(googletag.pubads());
        if (Object.keys(triggers).length > 0) {
          interstitialSlot.setConfig({ interstitial: { triggers } });
        }
        console.log("interstitial is loading...");

        googletag.pubads().addEventListener("slotOnload", function (event) {
          if (interstitialSlot === event.slot) {
            console.log("Interstitial is loaded.");
          }
        });

        resolve({
          key: "gpt",
          code,
          type: "interstitial",
          adUnit: {
            slot: interstitialSlot,
          },
        });
      } else {
        reject(
          new Error(
            "AdManager: addInterstitialAdUnit: slot did not create. Web interstitial is not supported on this page.",
          ),
        );
      }
    });
  });
}

export function refreshAdUnit(slot: googletag.Slot) {
  addToQueue(() => {
    googletag.pubads().refresh([slot]);
  });
}

export function enableAds() {
  addToQueue(() => {
    googletag.enableServices();
  });
}

export function setTargeting(key: string, value: string | string[]) {
  addToQueue(() => {
    googletag.pubads().setTargeting(key, value);
  });
}

function addToQueue(func: () => void): void {
  googletag.cmd.push(func);
}
