// This file will contain all the utility functions

/**
 * Functions to load a script file
 *
 * @param {string} src script URL
 * @returns {Promise<void>} Promise that resolves when script is loaded
 */
export const loadScript = (src: string) => {
  return new Promise<void>(function (resolve, reject) {
    const timeout = setTimeout(
      reject,
      10000,
      `Timeout exceeded. Could not load ${src}`,
    );
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = src;
    s.async = true;
    s.onerror = function (err) {
      clearTimeout(timeout);
      reject(new Error(`Failed to load script: ${src}`, { cause: err }));
    };
    s.onload = function () {
      clearTimeout(timeout);
      resolve();
    };
    const t = document.getElementsByTagName("script")[0];
    if (t && t.parentElement) {
      t.parentElement.insertBefore(s, t);
    } else {
      reject(new Error("Failed to find script tag."));
    }
  });
};

/**
 * Get URL parameter
 *
 * @param name
 * @returns
 */
export function getUrlParameter(name: string) {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}
