import type { Server } from "@rampjs/schema";
import * as cookies from "./cookies";

const input = process.env.RAMPJS_SERVER_URL as string;

export function canPersonalizeAds() {
  const usprivacy = cookies.get("usprivacy");
  // Opt-Out Sale
  // N = No, Y = Yes, - = Not Applicable. For use ONLY when CCPA does not apply.
  switch (usprivacy?.charAt(2)) {
    case "N":
      return true;
    case "Y":
      return false;
  }
}

export function createIframe(srcdoc: string) {
  const iframe = document.createElement("iframe");
  iframe.srcdoc = srcdoc;
  iframe.style.border = "0";
  iframe.width = "100%";
  return iframe;
}

export function fetchServerResponse(body: Server.Body) {
  return fetch(input, { body: JSON.stringify(body), method: "POST" });
}

/** Get iframe scroll height */
export function getScrollHeight(iframe: HTMLIFrameElement) {
  return iframe.contentWindow?.document?.documentElement?.scrollHeight;
}

/**
 * Returns the terms from the meta tag with the name `ramp-js-keywords` or
 * `keywords` if present, otherwise returns an empty string.
 */
export function getTerms(): string {
  const metaTag =
    document.querySelector('meta[name="rampjs-keywords"]') ||
    document.querySelector('meta[name="keywords"]');
  return metaTag?.getAttribute("content") ?? "";
}

/** RAMP.js unit loaded event */
export function rampJsUnitLoaded(container: string, framework: string) {
  return new CustomEvent("rampJsUnitLoaded", {
    detail: { container, framework },
  });
}

export function rampJsAdsLoaded(
  containerName: string,
  adsLoaded: boolean,
  isExperimentVariant: boolean,
  callbackOptions: any,
) {
  return new CustomEvent("adsLoaded", {
    detail: { containerName, adsLoaded, isExperimentVariant, callbackOptions },
  });
}
